<template>
  <div class="about" v-touch:swipe.left="onSwipeLeft" v-touch:swipe.right="onSwipeRight">
    <div class="back" @click="onSwipeRight">
        <img src="../assets/img/home/back.png" alt="">
      </div>
    <div class="title">
      <h1>First Semester {{user.level_name}}</h1>
      <h2>Academic Report</h2>
      <p>2023春季{{user.level_name}}学情报告</p>
    </div>
    <div class="student-r">
      <div class="student-img">
        <img :src="user.avatar" alt=""  ondragstart="return false;">
      </div>
      <div class="name">{{user.name}} · {{user.level_name}}</div>
    </div>
    <div class="to" @click="onSwipeLeft">
          <img src="../assets/img/home/right.png" alt="">
      </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
export default {
  data() {
    return {
      title: '',
      user: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('student'));
    console.log(this.user);
  },
  // beforeRouteLeave(to, from, next) {
  //   // eslint-disable-next-line no-debugger
  //   const from1 = from;
  //   if (to.name !== 'Home') {
  //     from1.meta.keepAlive = true;
  //   } else {
  //     from1.meta.keepAlive = false;
  //   }
  //   setTimeout(() => {
  //     next();
  //   }, 100);
  // },
  mounted() {
    // js
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    onSwipeLeft() {
      this.$router.push({
        name: 'StudentInfo',
      });
    },
  },
};
</script>
<style lang="less">
.about{
  .back img {
      width: 0.82rem;
  }
  width: 100vw;
  min-height: 100vh;
  background: no-repeat;
  background-image: url('../assets/img/login/bg.png') ;
  background-size: 100% auto;
  overflow: hidden;
  .student-r{
    position: fixed;
    right: 1.12rem;
    bottom: 1.06rem;
    .student-img{
      width: 1.86rem;
      height: 2.45rem;
      border-radius: 50%;
      background: #666;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img{
        height: 100%;
      }
    }
    .name{
      font-size: 0.16rem;
      text-align: center;
      margin-top: 0.25rem;
    }
  }
  .title{
    width: 4.7rem;
    text-align: right;
    margin-top: 1.85rem;
    margin-left: 0.97rem;
    h1{
      font-size: 0.38rem;
      line-height: 0.38rem;
      margin-bottom: 0.26rem;
    }
    h2{
      font-size: 0.56rem;
      line-height: 0.56rem;
      margin-bottom: 0.2rem;
    }
    p{
      color: #666;
      font-size: 0.18rem;
    }
  }
}

</style>
